import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject  } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { LocalStorageService } from './local-storage.service';
import { ModalService } from "./modal.service";
import { Router } from '@angular/router';

//import the models
import { Order } from '../../reserve/models/order.model';
import { Cars } from '../../reserve/models/cars.model';

// Enviroment Variable
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  public isLoading = new BehaviorSubject(false); 

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private router: Router,
    private modalServ:ModalService ) { }

  // Post data to remote server, and handle the success
  addOrder( order: Order ): Observable<any> {
    let headers = new HttpHeaders();
    headers  = headers.append('authorization', environment.token);


    return this.http.put<any>( environment.endpointOrder, order, { headers } )
    .pipe(
      tap(response => {
        
        //if no associated error codes, create local storage
        if(!response.code) this.localStorageService.saveStorage(this.mapOrderToStorage(response), 'order');
      }),
      catchError((err, caught) => {
        if(err.status == 422){
          //validation error. Trigger modal.
          if(err.error.errors[0]){
            this.displayValidationError(err.error.errors[0][Object.keys(err.error.errors[0])[0]]);
          }else{
            this.displayValidationError(err.error.message);
          }
        }else if(err.status == 403 || err.status == 401){
          //session expiration error
          this.modalServ.showModal("Error", "Su reserva expiró.", this.redirectToHome.bind(this));
        }else{
          //default error
          this.modalServ.showModal("Error", "Se produjo un error inesperado. Inténtelo más tarde. ("+err.status+")");
        }
        throw("Something went wrong");
      })
    );
  }

  /**
   * Displays validation error as a modal on webpage.
   * @param errorObject 
   */
  public displayValidationError(errorMsg:string){
    let message:string = errorMsg.replace(/[\-_]/g, function (m) {
      return {
          'phone': 'teléfono',
          'name': 'nombre',
          'lastName': 'apellido',
          'identity_card': 'DNI',
          'prefix': 'prefijo',
          'dealer': 'concesionaria'
      }[m];
  });
    this.modalServ.showModal("Error", message);
  }

  /**
   * Redirect to hamepage
   */
  public redirectToHome(){
    this.router.navigateByUrl('/');
  }

  /**
   * Map order to localstorage.
   * @param response 
   */
  public mapOrderToStorage(response:any){
    let order:Order = new Order();

    // token
    order.token = response.token || null;
    order.estado = response.estado || null;
    // parse root level params
    order.model_id = response.model_id || null;
    order.color_id = response.color_id || null;
    order.mp_order_id = response.mp_order_id || null;
    order.dealer_sf_id = response.dealer_sf_id || null;
    order.dealer = response.dealer || null;
    order.mp_order_id = response.mp_order_id || null;
    order.mp_order_ref = response.mp_order_ref || null;
    order.mp_order_payment = response.mp_order_payment || null;
    order.nostock = response.nostock || null;

    //parse client
    if(response.client){
      order.name = response.client.name || null;
      order.lastName = response.client.lastname || null;
      order.prefix = response.client.prefix || null;
      order.phone = response.client.phone || null;
      order.email = response.client.email || null;
      order.identity_card = response.client.identity_card || null;
      order.termsAndConditions = response.client.termsAndConditions || null;
    }else{
      order.name = null;
      order.lastName = null;
      order.prefix = null;
      order.phone = null;
      order.email = null;
      order.identity_card = null;
      order.termsAndConditions = null;
    }
    return order;
  }

  //reset the local storage object and store the cardata
  public createStep1(car : Cars){
    let order = new Order;
    order.model_id = car.model_id;
    order.color_id = car.color_id;
    return order; 
  }

  // get the local storage object and add client data
  public createStep2(userData: Order){
    let order = this.localStorageService.getStorage('order');
    order.name = userData.name;
    order.lastName = userData.lastName;
    order.prefix = userData.prefix;
    order.phone = userData.phone;
    order.identity_card = userData.identity_card;
    order.email = userData.email;
    order.termsAndConditions = userData.termsAndConditions;
    return order;
  }

  // get the local storage object and add dealer
  public createStep3(dealername, dealercode){
    let order = this.localStorageService.getStorage('order');
    order.dealer = dealername;
    order.dealer_sf_id = dealercode;
    return order;
  }

  // get the local storage order and add the mp preference id
  public createStep4(mp_id:string, merchang_ref:string, payment_id:string){
    let order = this.localStorageService.getStorage('order');
    order.mp_order_ref = merchang_ref;
    order.mp_order_payment = payment_id;
    if(order.mp_order_id !== mp_id){
      this.modalServ.showModal("Error", "Se produjo un error inesperado. Contáctese con un vendedor.")
      return false;
    }
    return order;
  }
}
