// Native Angular
import { Component, OnInit } from '@angular/core';

// Icon Font Awesome
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faInstagram, faYoutube, faTwitter } from '@fortawesome/free-brands-svg-icons';

// Declara Interfaz Model (Esta interfaz debe eliminarce cuando se obtengan los modelos de un endpoint y crearce en Models)
interface Model {
  name: string;
  url: string;
}

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  // Declarate class properties
  public faChevrondown = faChevronDown;
  public faFacebook = faFacebookF;
  public faInstagram = faInstagram;
  public faYoutube = faYoutube;
  public faTwitter = faTwitter;

  public models: Model[] = [
    { name: 'impetus', url: 'https://pulse.fiat.com.ar/ ' }
  ];

  constructor() { }

  ngOnInit() {
  }

}
