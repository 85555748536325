// Interface Statement
interface OrderInterface {
    token?: string;
    estado?: string;
    model_id?: number;
    color_id? :number;
    colorImg?: string;
    name?: string;
    lastName?: string;
    identity_card?: number;
    email?: string;
    prefix?: number;
    phone?: number;
    dealer?: string;
    dealer_sf_id?: string;
    mp_order_id?: string;
    mp_order_ref?: string;
    mp_order_payment?: string;
    acceptEmail?: boolean;
    acceptPhone?: boolean;
    termsAndConditions?:boolean;
    nostock?: boolean;
}

// Class Statement
export class Order implements OrderInterface {

    // tslint:disable-next-line: variable-name
    token: string;
    estado: string;
    model_id: number;
    color_id :number;
    colorImg: string;
    name: string;
    lastName: string;
    identity_card: number;
    email: string;
    prefix: number;
    phone: number;
    dealer: string;
    dealer_sf_id: string;
    mp_order_id: string;
    mp_order_ref: string;
    mp_order_payment: string;
    acceptEmail: boolean;
    acceptPhone: boolean;
    termsAndConditions: boolean;
    nostock: boolean;
}
