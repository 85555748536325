import { Component, OnInit } from '@angular/core';
import { ModalService } from "../../core/services/modal.service";

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  public showModal = false;
  public modalTitle = null;
  public modalMsg = null;
  public modalNext = null;

  constructor(private modalServ:ModalService) { }

  ngOnInit() {
    this.modalServ.change.subscribe(data => {
      this.showModal = data.show;
      this.modalTitle = data.title;
      this.modalMsg = data.msg;
      this.modalNext = data.next;
    });
  }

}
