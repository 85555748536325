import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalize'
})
export class CapitalizePipe implements PipeTransform {

  transform( value: any, all: boolean = true ): any {

      // Transform our value into lowercase and separate the words.
      value = String(value).toLowerCase();
      // tslint:disable-next-line: prefer-const
      let names: string[] = value.split(' ');

      // Validate how many words to capitalize.
      if ( all ) {
        // tslint:disable-next-line: forin
        for (const i in names) {
          names[i] = names[i][0].toUpperCase() + names[i].substr(1);
        }
      } else {
        names[0] = names[0][0].toUpperCase() + names[0].substr(1);
      }

      // Returns the capitalized words.
      return names.join(' ');
  }

}
