// my-loader.component.ts
import {ViewChild, ElementRef} from '@angular/core';
import { gsap } from 'gsap'
import { Component, OnInit } from '@angular/core';
import { OrderService } from '../../../core/services/order.service';
import { faTencentWeibo } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-preloader',
  templateUrl: './preloader.component.html',
  styleUrls: ['./preloader.component.scss']
})
export class PreloaderComponent implements OnInit {

  loading: boolean;

  @ViewChild('preloader', { static: false }) preloaderRef: ElementRef;

  constructor(private orderService: OrderService) {

    this.orderService.isLoading.subscribe((v) => {
      console.log("isloading: ", this.loading);
      if(this.loading!=v){
        if(v){
          gsap.killTweensOf('#preloaderelem');
          gsap.to('#preloaderelem', {duration:0.5, display:"block", autoAlpha:1});
        }else{
          gsap.killTweensOf('#preloaderelem');
          gsap.to('#preloaderelem', {duration:0.5, display:"none", autoAlpha:0, overwrite:true});
        }
      }
      this.loading = v;
    });
  }
  
  ngOnInit() {
    if(!this.loading){
      gsap.killTweensOf('#preloaderelem');
      gsap.to('#preloaderelem', {duration:0.5, display:"none", autoAlpha:0, overwrite:true});
    }
  }

}
