// Native Angular
import { Component, OnInit, Input  } from '@angular/core';

import { Router } from '@angular/router';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {

  // Declarate Input property
  @Input() buttonContent: String;
  @Input() disabled: Boolean;

  constructor( private router: Router ) { }

  ngOnInit() {
    
  }

  navigate(event): void {
    this.router.navigate(['/reservar']);
  }

}
