import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Order } from '../../reserve/models/order.model';


@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor(private router: Router) { }

  saveStorage( value: any, key: string ) {
    const saveOrder = JSON.stringify(value);
    localStorage.setItem( key,  saveOrder );
  }

  getStorage( key: string ) {
    const order = JSON.parse( localStorage.getItem(key) );
    return order;
  }

  public sendToCorrectStep(order:Order){
    switch(order.estado){
      case "Color":
        this.router.navigateByUrl('reservar/registro');
      break;
      case "Client data":
        this.router.navigateByUrl('reservar/concesionarios');
      break;
      case "Dealer":
        this.router.navigateByUrl('reservar/checkout');
      break;
      case "Mp Checkout":
        this.router.navigateByUrl('reservar/gracias');
      break;
      default:
        return;
    }
  }
}
