import { Injectable, Output, EventEmitter } from '@angular/core'

@Injectable({
    providedIn: 'root'
})
export class ModalService {

    public data:any = null;

    @Output() change: EventEmitter<boolean> = new EventEmitter();

    constructor(){}

    showModal(title:string, msg:string, next:Function = null) {
        this.data = new Object();
        this.data.title = title;
        this.data.msg = msg;
        this.data.show = true;
        this.data.next = next;
        this.change.emit(this.data);
    }

    closeModal(){
        this.data = new Object();
        this.data.show = false;
        this.change.emit(this.data);
    }

}