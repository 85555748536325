// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {

  production: false,
  googleMapsApiKey: 'AIzaSyBK8hVq9mCaod6ux7urVyjFfc380wObpUw',
  endpontConcesionarios: 'https://tiendafiat.com.ar/app/api/dealers',
  endpointOrder: 'https://tiendafiat.com.ar/app/api/order',
  token: 'Aca ira el token de seguridad',
  endpontCars: './assets/data-ecommerce-fiat.json',
  endpointStock: 'https://tiendafiat.com.ar/app/api/stock',
  endpointSession: 'https://tiendafiat.com.ar/app/api/checkSession',
  session_expires: 20,
  endpointSlides: './assets/slider-ecommerce-fiat.json' 
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
