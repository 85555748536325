// Native Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// Font Awesome Module
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

// Components
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { ButtonComponent } from './components/button/button.component';
import { ModalComponent } from './components/modal/modal.component';
import { PreloaderComponent } from './components/preloader/preloader.component';

// Pipes
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { CustomCurrency } from './pipes/customCurrency.pipe';


import { OrderService } from '../core/services/order.service';
import { OrderInterceptor } from '../core/interceptors/order-interceptor.service';


@NgModule({
  declarations: [ModalComponent, HeaderComponent, FooterComponent, ButtonComponent, CapitalizePipe, CustomCurrency, PreloaderComponent],
  imports: [
    CommonModule,
    FontAwesomeModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    ButtonComponent,
    CapitalizePipe,
    CustomCurrency,
    ModalComponent,
    PreloaderComponent
  ],
  providers: [
    OrderService,
    { provide: HTTP_INTERCEPTORS, useClass: OrderInterceptor, multi: true }
  ]
})
export class SharedModule { }
