// Native Angular
import { Component, OnInit } from '@angular/core';

// Icon Font Awesome (Se comento de forma provisoria)
// import { faBars } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  // Declarate class properties (Se comento de forma provisoria)
  // public faBars = faBars;

  constructor() { }

  ngOnInit() {
  }

}
