import { Component, OnInit, Input } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ModalService } from "../../../core/services/modal.service";

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

    public FaTimes = faTimes;

    @Input() title: string = "default title";
    @Input() msg: string= "default msg";
    @Input() next: any;

    constructor(
        private modal:ModalService
    ) {
        
    }

    ngOnInit() {
        
    }

    close(){
        if(this.next){
            this.next();
        }
        this.modal.closeModal();
    }

}